import React, { useState } from "react"

// Assets
import LogoImage from "../images/logo.png"

const Navbar = () => {
  return (
    <nav className="bg-transparent">
      <div className="max-w-7xl mx-auto px-8 py-5">
        <div className="flex items-center justify-between h-16">
          <div className="w-full justify-between flex items-center">
            <div className="text-white flex items-center flex-shrink-0 font-poppins">
              <img className="h-16" src={LogoImage} alt="Logo"></img>

              <span className="ml-4 text-2xl">DevJournal.ai</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
