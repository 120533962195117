import Button from "../components/Atoms/button"
import React from "react"
import { createClient } from '@supabase/supabase-js'
import { useEffect, useState } from "react";

const Footer = () => {
  const supabase = createClient('https://abewanxopycnxbfiyiqh.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFiZXdhbnhvcHljbnhiZml5aXFoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY1NTIxNzYsImV4cCI6MjAyMjEyODE3Nn0.kfhKLtmoSd4Ag8xgbl7uXpIp00O47hmhS0mzAiPtEK4')

  useEffect(() => {
    loginWithUser()
  }, [])

  const loginWithUser = async () => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: 'alexandroskaza23@gmail.com',
      password: 'DevJournal',
    })
  }

  const [emailValue, setEmailValue] = useState("")

  const writeEmailToOurDB = async () => {
    // validate if emailValue is filled and is a valid email
    if (!emailValue) {
      alert("Please enter a valid email.")
      return
    }

    const emailRegex = /\S+@\S+\.\S+/
    if (!emailRegex.test(emailValue)) {
      alert("Please enter a valid email.")
      return
    }

    const { data, error } = await supabase
      .from('emails')
      .insert([
        { email: emailValue },
      ])

    if (error) {
      alert("Something went wrong. Please try again.")
    } else {
      alert("Thank you for your interest. We will notify you when ready.")
    }
  }

  return (
    <div className="max-w-7xl mx-auto p-20 mt-10 bg-lightblack rounded-xl">
      <div className="grid grid-cols-2 xxs:grid-cols-1 lg:grid-cols-2">
        <div>
          <h1 className="text-white text-4xl text-bold">DevJournal.ai</h1>
          <p className="text-white text-sm mt-5 opacity-50">
            Built by a fellow developer who loves to learn new things everyday.
            If you want to connect with me you can find me on {" "}
            <a
              className="text-purple-200 font-semibold"
              href="https://www.linkedin.com/in/alexandros-kazantzidis/"
              target="_blank"
              rel="noreferrer"
            >
              Linkedin.
            </a>
            <br></br>
            <br></br>
            Working on Beta version. If you want to be notified when ready, leave your email below.
          </p>

          <div className="mt-10 flex flex-col sm:flex-row sm:block items-center">
            <input
              type="email"
              placeholder={"Email"}
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              className="mb-4 sm:mb-0 px-2 py-2 rounded-lg bg-white bg-opacity-20 border-transparent flex-1 appearance-none text-white font-poppins focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent sm:width-full md:width-full xxs:text-sm"
            ></input>

            <Button
              onClick={() => writeEmailToOurDB()}
              colorClass="bg-gradient-to-r from-pink to-purple"
              title="Notify me when ready"
              textColor="text-white"
              marginClass="ml-5"
            ></Button>
          </div>

          <h1 className="text-white text-lg mt-32">
            © copyright{" "} 2024 DevJournal.ai
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Footer
